/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  font-size: 16px;
}

/* Global styles */
body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.hero > img{
  width: 100%;
  height: auto;
}

@supports(object-fit: cover){
.hero > img{
  height: 100%;
  object-fit: cover;
  object-position: center center;
  }
}

header {
  background-color: #ffffff; /* White background for header */
  padding: 0.625rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  border-left: 0.5rem solid goldenrod;
  border-right: 0.5rem solid goldenrod;
}

.imageWrapper{
  display: inline-flex;
  align-items: center;
  font-size: 2.15rem;
  color: #303;
}

header > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header img {
  display: block;
  width : 3.125rem;
  height: 3.75rem;
}

nav ul {
  list-style: none;
  text-align: center;
}

nav ul li {
  display: inline;
  margin: 0 0.625rem;
  padding: 0.625rem;
  background-color: bisque;
}

nav ul li a {
  text-decoration: none;
  color: #303;
  font-weight: bold;
}

nav ul li:hover{
  font-weight: bolder;
  background-color: seagreen;
}
nav ul li:hover a{
  color: white;
}
.hero {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.25rem;
  text-align: center;
  padding: 5rem 0 0.625rem 0;
  background-color: seagreen;
}

 h1 {
  font-size: 2.25rem;
}

 h2 {
  font-size: 2.25rem;
  margin-bottom: 2.5rem;
}

h1,h2{
  color: #333;
}

.hero-txt-1{
  color:#303;
}
.hero-txt-2{
  color: #FFC107;
  margin-bottom: 0;
}

.listWrapper{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
}

.listWrapper .card{
  width: 30%;   
}
.listWrapper .card img{
  width: 100%;
  border: 4px solid goldenrod;
}

.about,
.products,
.footer {
  padding: 3rem 0;
  background-color: #f9f9f9; /* Light gray background */
  text-align: center;
}

.footer{
  background-color: seagreen;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem;
}


.contact-info h3,.company-info h3{
  font-weight: bolder;
  color: white;
} 

.contact-info{
  text-align: left;
  width: 50%;
}
.company-info{
  text-align: left;
  width: 50%;
}

.about h2,
.products h2{
  margin-bottom: 2.5rem;
}


@media only screen and (max-width: 720px) {
  html {
  font-size: 10px;
  }
}