.team-section {
    padding: 2.5rem 0;
    background-color: #f7f7f7;
    text-align: center;
}

.team-members {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;
}

.team-member {
    margin: 0 1.25rem;
}

.team-card {
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.team-card:hover {
    transform: translateY(-5px) scale(1.05) rotate(2deg);
}

.team-card img {
    transform: scale(0.8);
    width: 100%;
    border-bottom: 1px solid #eee;
    transition: transform 0.3s ease;
}

.team-card:hover img {
    transform: scale(1.1);
}

.team-info {
    padding: 1.25rem;
}

.team-info h3 {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
    color: #333;
    text-wrap: nowrap;
}

.team-info p {
    font-size: 1.125rem;
    color: #666;
}
