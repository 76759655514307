/* Our Story Section */
.our-story {
    padding: 0 1.25rem;
    background-color: #f7f7f7;
    text-align: center;
}

.story-content ul {
    padding: 0;
    margin-top: 2.5rem;
    text-align: left;
}

.story-content ul li {
    font-size: 1.125rem;
    line-height: 1.6;
    margin-bottom: 0.9375rem;
    color: #333;
    border-left: 0.3125rem solid goldenrod;
    padding-left: 0.625rem;
    list-style-type:none;
    background-color: aliceblue;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.image-wrapper {
    box-shadow: 0px 0px 1.25rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    overflow: hidden;
}

.image-wrapper img {
    max-width: 100%;
    display: block;
    border-radius: 0.5rem;
}