.faq-section {
    background-color: #eee;
    padding: 2.5rem 0;
    text-align: center;
  }
  
  .faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.25rem; /* 20px converted to rem */
  }
  
  .faq-accordion {
    margin-top: 1.25rem; /* 20px converted to rem */
  }
  
  .faq-item {
    margin-bottom: 1.25rem; /* 20px converted to rem */
    border-radius: 0.5rem; /* 8px converted to rem */
    overflow: hidden;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  }
  
  .question {
    background-color: #fff;
    color: seagreen;
    font-size: 1.2rem;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .question.active {
    font-weight: bold;
  }
  
  .toggle-icon {
    font-size: 1.5rem;
  }
  
  .answer {
    background-color: #f9f9f9;
    color: #333;
    font-size: 1rem;
    padding: 1rem;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
  }
  
  .answer.active {
    max-height: 12.5rem; /* 200px converted to rem */
  }
  
  @media (max-width: 48rem) { /* 768px converted to rem */
    .container {
      padding: 0 0.625rem; /* 10px converted to rem */
    }
  }
  