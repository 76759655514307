.benefits-container{
    padding: 1.5rem 1.25rem .5rem 1.25rem;
    background-color: #f7f7f7;
    text-align: center;
}
.benefits-body-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }

  .benefits-container h2 {
    margin-bottom: 0;
  }

  .benefits-container h3 {
    margin-bottom: 1.25rem;
  }
  
  .benefit {
    width: 15rem; /* 250px converted to rem */
    text-align: center;
    margin-bottom: 1.25rem; /* 20px converted to rem */
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 4px solid seagreen;
    outline: 3px solid bisque;
    padding: 1rem;
    position: relative;
  }
  
  .benefit img {
    width: 7.5rem; /* 120px converted to rem */
    height: 7.5rem; /* 120px converted to rem */
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.625rem; /* 10px converted to rem */
    transition: transform 0.3s ease-in-out;
  }
  
  .benefit h3 {
    font-size: 1.2rem;
    color: #333;
  }
  
  .benefit img:hover {
    transform: scale(1.1);
  }
  