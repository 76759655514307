.testimonials-section {
    padding: 2.5rem 0;
    background-color: #f7f7f7;
    text-align: center;
    height: 35rem;
    background-color: bisque;
}

.testimonial-carousel {
    display: flex;
    justify-content: center;
    position: relative;
}

.testimonial {
    width: 37.5rem;
    padding: 2.5rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.1);
    margin: 0 1.25rem;
    opacity: 0;
    transition: opacity 0.5s ease;
    position: absolute;
    left: 19%;
    top: 0;
}

.testimonial.active {
    opacity: 1;
    transition: opacity 0.5s ease;
    z-index: 1;
}

.testimonial img {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    margin-bottom: 1.25rem;
    transition: transform 0.3s ease;
}

.testimonial p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #666;
}

.testimonial .author {
    display: block;
    margin-top: 1.125rem;
    font-style: italic;
    color: #888;
}

.testimonial-carousel {
    display: flex;
    transition: transform 0.5s ease;
    margin-top: 3.125rem;
}

@media only screen and (max-width: 720px) {
    .testimonial {
        width: 95%;
        left : 0%;
    }
  }